<router-outlet></router-outlet>
<div *ngIf="showLoginComponent && (showLanguageSwitcher || publicDocuments.length > 0)" class="login-toolbar">
  <mat-toolbar class="p-0 mat-elevation-z1">
    <div fxFill fxFlex fxLayout="row" fxLayoutAlign="end center">
      <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

        <ng-container *ngIf="publicDocuments.length > 0">
          <button (click)="openPublicDocumentsDialog()" aria-label="Toggle quick panel"
                  class="mat-icon-button quick-panel-toggle-button"
                  matTooltip="{{'Documentation'| translate}}"
                  mat-icon-button>
            <mat-icon class="icon">auto_stories</mat-icon>
          </button>
        </ng-container>
        <ng-container>
          <div class="toolbar-separator"></div>
          <button (click)="openContactUsDialog()" aria-label="Toggle quick panel"
                  class="mat-icon-button quick-panel-toggle-button"
                  matTooltip="{{'ecase.common.contactUs'| translate}}"
                  mat-icon-button>
            <mat-icon class="icon">live_help</mat-icon>
          </button>
        </ng-container>

        <ng-container *ngIf="showLanguageSwitcher">
          <div class="toolbar-separator"></div>
          <button [matMenuTriggerFor]="languageMenu" class="language-button"
                  fxShow.gt-xs
                  mat-button>
            <div fxLayout="row" fxLayoutAlign="center center">
              <span class="iso text-uppercase">{{selectedLanguage?.id}}</span>
            </div>
          </button>

          <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
            <ng-container *ngFor="let lang of languages">
              <button (click)="setLanguage(lang)" [disabled]="lang.id === selectedLanguage.id" mat-menu-item>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <span class="iso">{{lang.nativeName}}</span>
                </div>
              </button>
            </ng-container>
          </mat-menu>
        </ng-container>


      </div>
    </div>
  </mat-toolbar>
</div>

<div *ngIf="showLoginComponent" fxLayout="row" fxLayoutAlign="start" id="login">
  <div *ngIf="isShowCookiePolicyPopUpEnabled && !isUserAcceptedCookiePolicy && !isPlatFormMobile" fxLayout="column"
       fxLayoutAlign="start" style="width: 100%; overflow-y: auto;">
    <ng-container *ngTemplateOutlet="unSupportedBrowserPopUp;"></ng-container>
    <ng-container *ngTemplateOutlet="LoginFieldsDiv;"></ng-container>
    <ng-container *ngTemplateOutlet="cookiePolicyPopup;"></ng-container>
  </div>

  <ng-container *ngIf="!(isShowCookiePolicyPopUpEnabled && !isUserAcceptedCookiePolicy && !isPlatFormMobile)">
    <div fxLayout="column" fxLayoutAlign="start" style="width: 100%; overflow-y: auto;">
      <ng-container *ngTemplateOutlet="unSupportedBrowserPopUp;"></ng-container>
      <ng-container *ngTemplateOutlet="LoginFieldsDiv;"></ng-container>
    </div>
  </ng-container>

  <div *eCaseIfOnDom [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}"
       id="login-form-wrapper">

    <ng-container *ngIf="isPlatFormMobile">
      <ng-container *ngTemplateOutlet="unSupportedBrowserPopUp;"></ng-container>
    </ng-container>

    <div>
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxFlex="40">
        </div>
        <!-- <mat-form-field *ngIf="showLanguageSwitcher" appearance="outline" class="languageSwitcher" fxFlex="60">
           <select (ngModelChange)="setLanguage($event)" [(ngModel)]="selectedLanguage"
                   [attr.aria-label]="'language selector'" id="languageSelect"
                   matNativeControl
                   role="listbox">
             <option *ngFor="let language of languages" [attr.aria-labelledby]="'languageSelect'" [ngValue]="language"
                     role="option">{{language.label}}
             </option>
           </select>
         </mat-form-field>-->
      </div>
      <div id="login-form">
        <div class="text" style="color:red !important;text-align:justify;" [innerHTML]="'loginToYourAccount' | translate"></div>
        <main>
          <form (ngSubmit)="authenticateCredentials()" [formGroup]="loginForm" name="loginForm">
            <mat-label><span style="color: red">*  </span>{{ 'email' | translate }}</mat-label>
            <mat-form-field appearance="outline" autocomplete="off" style="margin-bottom: 15px">
              <input [attr.aria-label]="'email' | translate" autofocus formControlName="email" id="email"
                     matInput maxlength="320">
              <mat-icon matSuffix>email_outline</mat-icon>
              <mat-error *ngIf="loginForm.get('email').errors?.required"
                         id="required-email-error-msg"> {{ 'emailIsRequired' | translate }}
              </mat-error>
              <mat-error *ngIf="!loginForm.get('email').errors?.required && loginForm.get('email').errors?.email"
                         id="pleaseEnterValidEmailAddress-error-msg">
                {{ 'pleaseEnterValidEmailAddress' | translate }}
              </mat-error>
              <mat-error
                *ngIf="!loginForm.get('email').errors?.required && !loginForm.get('email').errors?.email && loginForm.get('email').errors?.emailDomain"
                id="emailDomain-error-msg">
                {{ 'ecase.common.nosfuemailforsignin' | translate }}
              </mat-error>
            </mat-form-field>
            <br *ngIf="getWidthFromMaxlength()">
            <mat-label><span style="color: red">*  </span>{{ 'password' | translate }}</mat-label>
            <br *ngIf="getWidthFromMaxlength()">
            <mat-form-field appearance="outline">
              <input [attr.aria-label]="'password' | translate" [type]="isActive ? 'password' : 'text'"
                     autocomplete="off" formControlName="password" id="password"
                     matInput
                     maxlength="50">
              <div (click)="togglePassword()" (keydown.enter)="onKeyDown2($event)"
                   [attr.aria-label]="isActive ? 'visibility' : 'visibility_off'"
                   matRipple matSuffix role="button" style="cursor: pointer"
                   tabindex="0">
                <mat-icon>{{isActive ? 'visibility' : 'visibility_off'}}</mat-icon>
              </div>
              <mat-error *ngIf="loginForm.get('password').errors?.required "
                         id="password-error-msg"> {{ 'passwordIsRequired' | translate }}
              </mat-error>
              <mat-error *ngIf="loginForm.get('password').errors?.pattern "
                         id="password-error-msgPatern"> {{ 'passwordIsWeak' | translate }}
              </mat-error>


            </mat-form-field>
            <br *ngIf="getWidthFromMaxlength()">
            <span *ngIf="capsLockOn" class="text" style="color: red">{{'ecase.common.capslockon' | translate}}</span>

            <!-- <div class="remember-forgot-password" fxLayout="row"
                  fxLayout.xs="column" fxLayoutAlign="space-between center">-->
            <!-- <div class="remember-forgot-password" fxLayout="row"
                  fxLayout.xs="column" fxLayoutAlign="end">-->
            <!--<mat-checkbox class="remember-me" name="rememberme" aria-label="{{ 'rememberme' | translate }}" (change)="rememberMe($event)">-->
            <!--{{ 'rememberme' | translate }}-->
            <!--</mat-checkbox>-->
            <!-- <span class="text" style="text-align: center">{{ 'additionalInfoOnLoginPage' | translate }}</span>-->
            <!-- <a (click)="forgotPassword()" class="forgot-password"> {{ 'forgotPassword' | translate }}</a>
           </div>-->

            <div *ngIf="forgotPasswordEnabled" class="remember-forgot-password" fxLayout="column"
                 fxLayoutAlign="center center">
              <span class="text" style="text-align: center">{{ 'alreadyHaveAnAccountLoginPage' | translate }}</span>
              <a (click)="forgotPassword()" class="forgot-password">{{'forgotPassword' | translate }}</a>
            </div>
            <span *ngIf="errorMessage && errorMessage !== 'ecase.common.accountlockedbecauseofmultiplefailures' && errorMessage !== 'ecase.common.accountlockedbecauseofinactivity'"
                  id="errorMessage" style="color:red">{{errorMessage | translate}}</span>
            <div *ngIf="errorMessage && errorMessage === 'ecase.common.accountlockedbecauseofmultiplefailures'"
                  id="lockedErrorMessage" style="color:red">
              <span [innerHTML]="getExpirationMessage()"></span>
              <a (click)="openContactUsDialog()">{{'ecase.common.accountlockedbecauseofmultiplefailures.contactus' | translate}}</a>
              <span [innerHTML]="('ecase.common.accountlockedbecauseofmultiplefailures.after' | translate)"></span>
            </div>
            <div *ngIf="errorMessage && errorMessage === 'ecase.common.accountlockedbecauseofinactivity'"
                 id="lockedErrorMessage2" style="color:red">
              <span [innerHTML]="'ecase.common.accountlockedbecauseofinactivity' | translate"></span>
              <a (click)="openContactUsDialog()">{{'ecase.common.accountlockedbecauseofmultiplefailures.contactus' | translate}}</a>
              <span [innerHTML]="('ecase.common.accountlockedbecauseofmultiplefailures.after' | translate)"></span>
            </div>
            <div *ngIf="loginAuthenticationService.isUserRedirectedForLogIn" class="hasError">
              <h3 class="hasErrorText">{{'ecase.common.loginfirstmessage' | translate}}</h3>
            </div>
            <button [attr.aria-label]="'login' | translate" class="submit-button" color="accent" id="loginBtn"
                    mat-raised-button
                    type="submit">{{ 'login' | translate }}
            </button>
          </form>
          <div fxLayout="column" fxLayoutAlign="center center">
            <span class="text">{{ 'dontHaveAnAccount' | translate }}</span>
            <a (click)="register()" *ngIf="isAccountCreationActivated"
               class="link">{{'createAnAccount' | translate }}</a>
            <span *ngIf="!isAccountCreationActivated" class="text"
                  style="text-align: center">{{ 'ecase.common.accountcreationdisabled' | translate }}</span>
            <br><br>
            <span class="text" style="color: #808000; !important"
                  [innerHtml]="'ecase.common.contactInformation' | translate | sanitizeHtml"></span>
            <ng-container *ngIf="showInternalLogin">
              <br>
              <span class="text">{{ 'or' | translate }}</span>
              <br>
              <span class="text" [innerHTML]="'ecase.common.affliatedtosfu' | translate"></span>
              <a [href]="'https://' + baseUrl + '/api/saml/index.html'">
                <button [attr.aria-label]="'ecase.common.sfuinternallogin' | translate" class="submit-button"
                        color="accent" mat-raised-button
                        style="margin-top: 10px;">{{ 'ecase.common.sfuinternallogin' | translate }}
                </button>
              </a>
            </ng-container>
            <ng-container style="margin-top:20px;" *ngIf="hasGeneralDirectory">
              <button [attr.aria-label]="'ecase.common.generalDirectory' | translate" class="submit-button"
                      color="accent" mat-raised-button
                      style="margin-top: 50px;"
                      (click)="goGeneralDirectory()">{{ 'ecase.common.generalDirectory' | translate }}
              </button>
            </ng-container>
          </div>
        </main>
      </div>
    </div>

    <ng-container *ngIf="isShowCookiePolicyPopUpEnabled && !isUserAcceptedCookiePolicy && isPlatFormMobile">
      <ng-container *ngTemplateOutlet="cookiePolicyPopup;"></ng-container>
    </ng-container>

    <div>
      <div class="footer" fxLayout="row" fxLayoutAlign="end center">
        <span class="footerText">{{ 'ecase.common.poweredbysynto' | translate }}</span>
        <img [src]="'/assets/images/logos/syntoLogo.svg'" alt="eVision Synto Logo"
             style="height:25px;">
      </div>
    </div>
  </div>
</div>


<ng-template #LoginFieldsDiv>
  <div fxFlex fxHide fxShow.gt-xs id="login-intro">
    <div *eCaseIfOnDom [@animate]="{value:'*',params:{scale:'0.1'}}" class="logo">
      <img *ngIf="isDropShadowEnabled"
           [src]="(!logoSrc || logoSrc === '') ? '/assets/images/logos/syntoLogo.svg' : logoSrc"
           alt="Synto product logo"
           class="logoImg"
           style="filter: drop-shadow(0px 0px 5px #000);">
      <img *ngIf="!isDropShadowEnabled"
           [src]="(!logoSrc || logoSrc === '') ? '/assets/images/logos/syntoLogo.svg' : logoSrc"
           alt="Synto product logo"
           class="logoImg">
    </div>
    <div *eCaseIfOnDom [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}"
         class="title">
      {{ 'welcomeToEcase' | translate }}
    </div>
    <div *eCaseIfOnDom [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}"
         class="subtitle">
      {{ 'welcomeToEcaseSubHeading' | translate }}
    </div>
    <div *eCaseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}" [id]="'description'"
         [innerHTML]="'welcomeToEcaseDescription' | translate" class="description">
    </div>
    <div *eCaseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}"
         [innerHTML]="'additionalInfoOnLoginPage' | translate" class="additionalInfo">
    </div>
  </div>
</ng-template>

<ng-template #cookiePolicyPopup>
  <div *eCaseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}" [id]="'cookiePolicyDiv'"
       [ngClass]="isShowEnvironment ? 'cookiepolicyacceptpopup' : 'cookiepolicyacceptpopupnomargin'">
    <div fxLayout="{{isPlatFormMobile ? 'column' : 'row'}}" fxLayoutAlign="space-between center">
      <span [innerHTML]="cookiePolicyPopUpContent" class="cookiepolicycontent"></span>
      <ng-container *ngIf="isPlatFormMobile"><br></ng-container>
      <button (click)="acceptCookiePolicy()" [attr.aria-label]="'ecase.common.accept' | translate" color="accent"
              id="cookiePolicyAcceptBtn"
              mat-raised-button>{{ 'ecase.common.accept' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #unSupportedBrowserPopUp>
  <ng-container *ngIf="isBrowserUnsupported">
    <div *eCaseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}" [id]="'unSupportedBrowserDiv'"
         class="unsupportedbrowserpopup">
      <div [innerHTML]="textReplace(('ecase.common.unsupportedbrowserused' | translate), '$baseUrl',
      (baseUrl.includes('localhost') ? 'http://' + baseUrl : 'https://' + baseUrl))"
           style="font-weight: bold;color: white;padding-left: 15px"></div>
    </div>
  </ng-container>
</ng-template>


