<form [formGroup]="notificationSenderForm">
  <div *ngIf="_notificationSenderInput?.isSkipNotificationCheckboxVisible">
    <mat-checkbox (change)="updateForm($event)"
                  [formControlName]="'isNotificationSkipped'"
                  aria-label="{{ 'ecase.common.skipnotification' | translate }}">
      <span class="formLabel">{{ 'ecase.common.skipnotification' | translate }}  </span>
    </mat-checkbox>
  </div>

  <ng-container *ngIf="!notificationSenderForm.controls['isNotificationSkipped'].value">
    <div *ngIf="!_notificationSenderInput?.isTemplateSelectorDisabled">
      <span style="color:red">* </span>
      <label>{{'ecase.common.descriptionTemplate'| translate}}</label>
      <mat-form-field appearance="outline">
        <mat-select (valueChange)="templateChanged($event)"
                    [attr.aria-label]="'ecase.common.descriptionTemplate'| translate"
                    [formControlName]="'crmEmailTemplateId'"
                    id="ecase_common_descriptionTemplate">
          <mat-option [value]="">{{'ecase.common.pleaseSelect' | eCaseTranslate }}</mat-option>
          <mat-option *ngFor="let item of emailTemplates" [value]="item.id">{{item.value[selectedLang]}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div style="width: 50%">
      <span style="color:red">* </span>
      <mat-label>{{'ecase.common.sendTo' | translate}}
      </mat-label>
      <mat-form-field appearance="outline" style="margin-top: 0">
        <input [attr.aria-label]="'ecase.common.sendTo'| translate"
               [formControlName]="'sendTo'"
               id="ecase_common_sendTo"
               matInput
               type="text">
      </mat-form-field>
    </div>

    <div *ngIf="displayTeamMemberList" style="display: flex; flex-direction: row; flex-wrap: wrap; align-items: center">
      <div style="width: 75%;">
        <mat-label>{{'ecase.common.ccEmailTeamMemberList' | translate}}</mat-label>
        <mat-form-field appearance="outline">
          <mat-select name="ecase_common_ccEmail" id="ecase_common_ccEmail"
                      (selectionChange)="changeStatus($event)"
                      [formControlName]="'ccEmails2'"
                      [attr.aria-label]="'ecase.common.ccEmail' | translate" multiple
                      [disabled]="_notificationSenderInput?.isCcEmailsDisabled">>
            <mat-option *ngFor="let ccEmail of ccEmails2"
                        [value]="ccEmail.username">{{(ccEmail.familyName) +', '+(ccEmail.fistName) +' - '+ (ccEmail.role[selectedLang])}}</mat-option>
          </mat-select>
        </mat-form-field>
        <p class="counter-style">{{getTextLength('ccEmails')}} / 1000 {{'ecase.common.characters'| translate}}</p>
      </div>
    </div>


    <div style="display: flex; flex-direction: row; flex-wrap: wrap; align-items: center">
      <div style="width: 75%;">
        <mat-label>{{'ecase.common.ccEmail' | translate}}</mat-label>
        <mat-form-field appearance="outline" class="mat-form-field">
          <input [attr.aria-label]="'ecase.common.ccEmail' | translate"
                 [formControlName]="'ccEmails'"
                 id="ecase_common_ccEmail"
                 matInput
                 maxlength="1000"/>
          <mat-error *ngIf="(notificationSenderForm.controls['ccEmails'].errors?.pattern) &&
                         (notificationSenderForm.controls['ccEmails'].dirty ||
                          notificationSenderForm.controls['ccEmails'].touched)">
            {{ 'ecase.common.pleaseEnterValidEmailAddress' |
            eCaseTranslate }}
          </mat-error>
        </mat-form-field>
        <p class="counter-style">{{getTextLength('ccEmails')}} / 1000 {{'ecase.common.characters'| translate}}</p>
      </div>
      <div style="margin-left: 15px;margin-bottom: 2px">
        <button (click)="openCrmSearchDialog()"
                [disabled]="_notificationSenderInput?.isCcEmailsDisabled"
                class="eCase-dialog-positive-action-button"
                id="ecase_common_openCrmSearchDialog" mat-raised-button
        >{{'ecase.common.openCrmSearchDialog'| translate }}
        </button>
      </div>
    </div>





    <div style="width: 50%; display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
      <div style="width: 90%;">
        <span style="color:red">* </span>
        <mat-label>
          {{'ecase.common.sendDate' | translate}}
          ({{'ecase.defaultDatetimeWithTimezone' | translate }})
        </mat-label>

        <mat-form-field appearance="outline">
          <input [attr.aria-label]="'ecase.common.sendDate'| translate"
                 [formControlName]="'sendDate'"
                 [max]="_notificationSenderInput?.maxDateForSendDate"
                 [min]="_notificationSenderInput?.minDateForSendDate"
                 [ngxMatDatetimePicker]="picker"
                 id="ecase_common_sendDate"
                 matInput>
          <button (click)="picker.open();$event.stopPropagation();" mat-icon-button matSuffix type="button">
            <mat-icon>today</mat-icon>
          </button>
          <ngx-mat-datetime-picker [showSeconds]="false" #picker></ngx-mat-datetime-picker>
        </mat-form-field>
      </div>
    </div>

    <div>
      <span style="color:red">* </span>
      <mat-label>{{'ecase.common.subject' | translate}}
      </mat-label>
      <mat-form-field appearance="outline" style="margin-top: 0">
        <input [attr.aria-label]="'ecase.common.subject'| translate" [formControlName]="'subject'"
               id="ecase_common_subject"
               matInput
               maxlength="400"
               type="text">
      </mat-form-field>
      <p class="counter-style">{{getTextLength('subject')}} / 400 {{'ecase.common.characters'| translate}}</p>
    </div>

    <div [style.display]="isEditHeader ? '' : 'none'" id="notificationSenderQuillHeaderDiv" style="width: 100%;">
      <label>{{'ecase.common.header'| translate}}</label>
      <quill-editor (onContentChanged)="onContentCreated($event, 'header')"
                    [formControlName]="'header'"
                    [modules]="modules"
                    id="ecase_common_header"
                    maxLength="4000"
                    placeholder="{{'ecase.common.header' | translate }}">
        <div quill-editor-toolbar>
                <span class="formLabel" class="ql-formats">
                    <button [title]="'Bold'" aria-label="Bold" class="ql-bold" id="headerBold"></button>
                    <button [title]="'Italic'" aria-label="Italic" class="ql-italic" id="headerItalic"></button>
                    <button [title]="'Underline'" aria-label="Underline" class="ql-underline"
                            id="headerUnderline"></button>
                    <button [title]="'blockquote'" aria-label="blockquote" class="ql-blockquote"
                            id="headerblockquote"></button>
                    <button [title]="'code-block'" aria-label="code" class="ql-code-block" id="headercode"></button>
                    <button aria-label="sub" class="ql-script" id="headersub" value="sub"></button>
                    <button aria-label="super" class="ql-script" id="headersuper" value="super"></button>
                </span>
          <span class="formLabel" class="ql-formats">
                      <select [title]="'Alignment'" aria-label="Alignment" class="ql-align" id="headerlistbox1"
                              title="Alignment">
                        <option selected></option>
                        <option value="center"></option>
                        <option value="right"></option>
                        <option value="justify"></option>
                      </select>

                    <select class="ql-size" id="size" title="Size">
                        <option value="small"></option>
                        <option selected></option>
                        <option value="large"></option>
                        <option value="huge"></option>
                     </select>
                            <select class="ql-background"></select>
                        <select class="ql-color"></select>
                      <button [title]="'ordered'" aria-label="ordered" class="ql-list" id="vordered"
                              value="ordered"></button>
                      <button [title]="'bullet'" aria-label="bullet" class="ql-list" id="headerbullet"
                              value="bullet"></button>
                      <button [title]="'ql'" aria-label="ql" class="ql-indent" id="headerql" value="-1"></button>
                      <button [title]="'q2'" aria-label="q2" class="ql-indent" id="headerq2" value="+1"></button>

                      <button [title]="'clean'" aria-label="clean" class="ql-clean" id="headerclean"></button>
                      <button [title]="'link'" aria-label="link" class="ql-link" id="headerlink"></button>
                      <button [title]="'image'" alt="Smiley face" aria-label="image" class="ql-image"
                              id="headerimage"></button>
                      <button [title]="'video'" aria-label="video" class="ql-video" id="headervideo"></button>
                      <select class="ql-header" id="headerscope" title="Header">
                        <option value="1"></option>
                        <option value="2"></option>
                        <option value="3"></option>
                        <option value="4"></option>
                        <option value="5"></option>
                        <option value="6"></option>
                        <option selected></option>
                      </select>
                      <select class="ql-font">
                          <option selected></option>
                          <option value="serif"></option>
                          <option value="monospace"></option>
                      </select>
              </span>
        </div>
      </quill-editor>
      <p class="counter-style-quill">{{getTextLength('header')}} / 4000 {{'ecase.common.characters'| translate}}</p>
      <br>
    </div>
    <div *ngIf="!isEditHeader && _notificationSenderInput && _notificationSenderInput.crmEmailTemplateId">
      <label>{{'ecase.common.header'| translate}}</label>
      <button (click)="editHeader()"
              [disabled]="_notificationSenderInput?.isHeaderDisabled"
              class="eCase-dialog-positive-action-button"
              id="ecase_common_edit_header"
              mat-raised-button style="margin-left: 10px"
      >{{'ecase.common.editHeader'| translate }}
      </button>
      <div *ngIf="_notificationSenderInput.header" [innerHTML]="_notificationSenderInput.header"
           class="disabled-div"></div>
      <br>
    </div>

    <div id="notificationSenderQuillContentDiv" style="width: 100%;">
      <span style="color:red">* </span>
      <label>{{'ecase.common.edit.content'| translate}}</label>
      <quill-editor (onContentChanged)="onContentCreated($event, 'content')"
                    [formControlName]="'content'"
                    [modules]="modules"
                    id="ecase_common_content"
                    placeholder="{{'ecase.common.edit.content' | translate }}">
        <div quill-editor-toolbar>
                <span class="formLabel" class="ql-formats">
                    <button [title]="'Bold'" aria-label="Bold" class="ql-bold" id="contentBold"></button>
                    <button [title]="'Italic'" aria-label="Italic" class="ql-italic" id="contentItalic"></button>
                    <button [title]="'Underline'" aria-label="Underline" class="ql-underline"
                            id="contentUnderline"></button>
                    <button [title]="'blockquote'" aria-label="blockquote" class="ql-blockquote"
                            id="contentblockquote"></button>
                    <button [title]="'code-block'" aria-label="code" class="ql-code-block" id="contentcode"></button>
                    <button aria-label="sub" class="ql-script" id="contentsub" value="sub"></button>
                    <button aria-label="super" class="ql-script" id="contentsuper" value="super"></button>
                </span>
          <span class="formLabel" class="ql-formats">
                      <select [title]="'Alignment'" aria-label="Alignment" class="ql-align" id="contentlistbox1"
                              title="Alignment">
                        <option selected></option>
                        <option value="center"></option>
                        <option value="right"></option>
                        <option value="justify"></option>
                      </select>

                    <select class="ql-size" id="contentsize" title="Size">
                        <option value="small"></option>
                        <option selected></option>
                        <option value="large"></option>
                        <option value="huge"></option>
                     </select>
                            <select class="ql-background"></select>
                        <select class="ql-color"></select>
                      <button [title]="'ordered'" aria-label="ordered" class="ql-list" id="contentordered"
                              value="ordered"></button>
                      <button [title]="'bullet'" aria-label="bullet" class="ql-list" id="contentbullet"
                              value="bullet"></button>
                      <button [title]="'ql'" aria-label="ql" class="ql-indent" id="contentql" value="-1"></button>
                      <button [title]="'q2'" aria-label="q2" class="ql-indent" id="contentq2" value="+1"></button>

                      <button [title]="'clean'" aria-label="clean" class="ql-clean" id="contentclean"></button>
                      <button [title]="'link'" aria-label="link" class="ql-link" id="contentlink"></button>
                      <button [title]="'image'" alt="Smiley face" aria-label="image" class="ql-image"
                              id="contentimage"></button>
                      <button [title]="'video'" aria-label="video" class="ql-video" id="contentvideo"></button>
                      <select class="ql-header" id="contentscope" title="Header">
                        <option value="1"></option>
                        <option value="2"></option>
                        <option value="3"></option>
                        <option value="4"></option>
                        <option value="5"></option>
                        <option value="6"></option>
                        <option selected></option>
                      </select>
                      <select class="ql-font">
                          <option selected></option>
                          <option value="serif"></option>
                          <option value="monospace"></option>
                      </select>
              </span>
        </div>
      </quill-editor>
      <p class="counter-style-quill">{{getTextLength('content')}} / 4000 {{'ecase.common.characters'| translate}}</p>
    </div>
    <br>

    <div [style.display]="isEditFooter ? '' : 'none'" id="notificationSenderQuillFooterDiv" style="width: 100%;">
      <label>{{'ecase.common.footer'| translate}}</label>
      <quill-editor (onContentChanged)="onContentCreated($event, 'footer')"
                    [formControlName]="'footer'"
                    [modules]="modules"
                    id="ecase_common_footer"
                    maxLength="4000"
                    placeholder="{{'ecase.common.footer' | translate }}">
        <div quill-editor-toolbar>
                <span class="formLabel" class="ql-formats">
                    <button [title]="'Bold'" aria-label="Bold" class="ql-bold" id="footerBold"></button>
                    <button [title]="'Italic'" aria-label="Italic" class="ql-italic" id="footerItalic"></button>
                    <button [title]="'Underline'" aria-label="Underline" class="ql-underline"
                            id="footerUnderline"></button>
                    <button [title]="'blockquote'" aria-label="blockquote" class="ql-blockquote"
                            id="footerblockquote"></button>
                    <button [title]="'code-block'" aria-label="code" class="ql-code-block" id="footercode"></button>
                    <button aria-label="sub" class="ql-script" id="footersub" value="sub"></button>
                    <button aria-label="super" class="ql-script" id="footersuper" value="super"></button>
                </span>
          <span class="formLabel" class="ql-formats">
                      <select [title]="'Alignment'" aria-label="Alignment" class="ql-align" id="footerlistbox1"
                              title="Alignment">
                        <option selected></option>
                        <option value="center"></option>
                        <option value="right"></option>
                        <option value="justify"></option>
                      </select>

                    <select class="ql-size" id="footersize" title="Size">
                        <option value="small"></option>
                        <option selected></option>
                        <option value="large"></option>
                        <option value="huge"></option>
                     </select>
                            <select class="ql-background"></select>
                        <select class="ql-color"></select>
                      <button [title]="'ordered'" aria-label="ordered" class="ql-list" id="footerordered"
                              value="ordered"></button>
                      <button [title]="'bullet'" aria-label="bullet" class="ql-list" id="footerbullet"
                              value="bullet"></button>
                      <button [title]="'ql'" aria-label="ql" class="ql-indent" id="footerql" value="-1"></button>
                      <button [title]="'q2'" aria-label="q2" class="ql-indent" id="footerq2" value="+1"></button>

                      <button [title]="'clean'" aria-label="clean" class="ql-clean" id="footerclean"></button>
                      <button [title]="'link'" aria-label="link" class="ql-link" id="footerlink"></button>
                      <button [title]="'image'" alt="Smiley face" aria-label="image" class="ql-image"
                              id="footerimage"></button>
                      <button [title]="'video'" aria-label="video" class="ql-video" id="footervideo"></button>
                      <select class="ql-header" id="footerscope" title="Header">
                        <option value="1"></option>
                        <option value="2"></option>
                        <option value="3"></option>
                        <option value="4"></option>
                        <option value="5"></option>
                        <option value="6"></option>
                        <option selected></option>
                      </select>
                      <select class="ql-font">
                          <option selected></option>
                          <option value="serif"></option>
                          <option value="monospace"></option>
                      </select>
              </span>
        </div>
      </quill-editor>
      <p class="counter-style-quill">{{getTextLength('footer')}} / 4000 {{'ecase.common.characters'| translate}}</p>
      <br>
    </div>
    <div *ngIf="!isEditFooter && _notificationSenderInput && _notificationSenderInput.crmEmailTemplateId">
      <label>{{'ecase.common.footer'| translate}}</label>
      <button (click)="editFooter()"
              [disabled]="_notificationSenderInput?.isFooterDisabled"
              class="eCase-dialog-positive-action-button"
              id="ecase_common_edit_footer"
              mat-raised-button style="margin-left: 10px"
      >{{'ecase.common.editHeader'| translate }}
      </button>
      <div *ngIf="_notificationSenderInput.footer" [innerHTML]="_notificationSenderInput.footer"
           class="disabled-div"></div>
      <br>
    </div>
    <div *ngIf="_notificationSenderInput"
         [style.padding-bottom.px]="(_notificationSenderInput.notificationDocuments && _notificationSenderInput.notificationDocuments.length > 0) ? 0 : 10">
      <label class="md-button md-raised md-primary">{{'ecase.common.notificationattachments' | translate}}
        <span
          *ngIf="_notificationSenderInput.notificationDocuments && _notificationSenderInput.notificationDocuments.length > 0">({{transformFileSize(totalUploadedSize)}}
          )</span>
      </label>&nbsp;
      <!--Good looking upload start from here-->
      <div (click)="parentDivOnClick();$event.stopPropagation()" [ngClass]="'custom-file-upload'">
        <table style="width: 100%">
          <tr>
            <td [style.background]="'#FFFFFF'" style="border: 0;">
              <mat-icon>cloud_upload</mat-icon>
            </td>
            <td [style.background]="'#FFFFFF'" style="border: 0;">
              <label (click)="$event.stopPropagation()" class="label"
                     for="replaceFileUploadInput">{{'ecase.common.filestoUpload' | translate}}</label>
              <input (change)="onFileInput($event)"
                     [attr.aria-label]="'ecase.common.notificationattachments' | translate"
                     [formControlName]="'notificationDocuments'"
                     [multiple]='true'
                     id="replaceFileUploadInput"
                     name="replaceFileUploadInput" type="file"/>
            </td>
          </tr>
        </table>
      </div>
      <div
        *ngIf="_notificationSenderInput.notificationDocuments && _notificationSenderInput.notificationDocuments.length > 0">
        <br>
        <ng-container *ngFor="let item of _notificationSenderInput.notificationDocuments">
          <div *ngIf="isInstanceOfFile(item)">
            <span><a
              (click)="downloadFileAttachment(item)"> {{item.name}} ({{transformFileSize(item.size)}})</a></span>
            <button (click)="deleteFileAttachment(item)"
                    mat-icon-button>
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <div *ngIf="!isInstanceOfFile(item)">
            <span><a
              (click)="download('/api/viewDocument/' + item.edmRepositoryDocumentId)"> {{item.fileName}}
              ({{transformFileSize(item.fileSize)}})</a></span>
            <button (click)="deleteFileAttachment(item)"
                    mat-icon-button>
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</form>
