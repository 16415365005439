<div *ngIf="!bphChange.hide && bphChange.globalShowDetail" [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'">
  <ng-container>
    <ng-container *ngIf="!showAddTableForm">
      <ng-container *ngFor="let table of bphChange.budgetTables; let i = index;">
      <ng-container *ngIf="table.showLabel">
        <div
          [ngClass]="table && table.error && table.error[selectedLanguage] ? 'hasError' : 'noError'">
                     <span *ngIf="table && table.error && table.error[selectedLanguage]"
                           class="formFieldError">{{table.error[selectedLanguage]}}</span>
        </div>
        <table class="angular-table">
          <ng-container>
            <thead class="angular-table-thead">
            <tr *ngIf="table.showLabel" (click)="toggleTable(table)" class="angular-table-tr header-table">
              <th colspan="10" class="angular-table-th">
                <mat-icon *ngIf="table.teamFunded === '1'" style="cursor: pointer; position: absolute; margin-right: 5px; left: 1%;">
                  {{table.hidden ? 'add_circle_outline' : 'remove_circle_outline'}}
                </mat-icon>
                {{table.label[selectedLanguage]}}
<!--              //</th> && table.selectNo-->
<!--              //<th>-->
<!--                <div class="mat-form-field" style="margin-bottom: 10px" fxLayout="row" fxLayoutAlign="end center">-->
                <label *ngIf="table.id>1" id="example-radio-group-label"
                       style="cursor: pointer; position: absolute; margin-left: 5px; right:15%;">
                  <span style="color: red">* </span>{{'ecase.admindashboard.budgect.requestedbudget' | eCaseTranslate:false}}</label>&nbsp;&nbsp;
                <mat-radio-group *ngIf="table.id>1" (click)="checkForSelectNo($event,table); "
                  aria-labelledby="teamFunded" style="cursor: pointer; position: absolute; margin-left: 5px; right: 3%;"
                  [(ngModel)]="table.teamFunded">
                  <mat-radio-button value="1" >{{confChange?.isFundedConfiguration?.yes[selectedLanguage] || ('ecase.taxonomy.1.1' | eCaseTranslate:false)}}</mat-radio-button>&nbsp;
                  <mat-radio-button value="2" >{{confChange?.isFundedConfiguration?.no[selectedLanguage] || ('ecase.taxonomy.1.2' | eCaseTranslate:false)}}</mat-radio-button>
                </mat-radio-group>
<!--                </div>-->
              </th>
            </tr>
            <tr [hidden]="table.hidden || table.teamFunded !== '1'" class="angular-table-tr">
              <ng-container>
                <th></th>
                <th class="angular-table-th"><span style="color: red">*</span>
                  {{confChange.categoryLabel[selectedLanguage]}}
                </th>
                <th class="angular-table-th"><span style="color: red">*</span>
                  {{confChange.subCategoryLabel[selectedLanguage]}}

                  <mat-icon *ngIf="confChange.hasDialogForSubCategory"
                            style="cursor: pointer; font-size: 21px;"
                            (click)="openDialog(confChange.dialogForSubCategory)">help_outline
                  </mat-icon>

                </th>

                <th class="angular-table-th"><span style="color: red">*</span>
                  {{confChange.descriptionLabel[selectedLanguage]}}
                </th>

                <!---- new part -->
                <th *ngIf="confChange.hasJustificationAndDurationMonths" class="angular-table-th"><span style="color: red">*</span>
                  {{confChange.justificationLabel[selectedLanguage]}}
                </th>
                <th *ngIf="confChange.hasJustificationAndDurationMonths" class="angular-table-th"><span  *ngIf="confChange.isRequired"style="color: red">*</span>
                  {{confChange.DurationMonthsLabel[selectedLanguage]}}
                </th>
                <!--  end new part              -->
                <th class="angular-table-th">
                  {{confChange.subtotalLabel[selectedLanguage]}}
                </th>
                <th *ngIf="confChange.fundsSplitInstitution" class="angular-table-th"><span style="color: red">*</span>
                  {{confChange.fundsFromInstitutionLabel[selectedLanguage]}}
                </th>
                <th *ngIf="confChange.fundsSplitInstitution" class="angular-table-th"><span style="color: red">*</span>
                  {{confChange.fundsFromOtherInstitutionLabel[selectedLanguage]}}
                </th>
                <th *ngIf="confChange.hasComments" class="angular-table-th">{{confChange.commentsLabel[selectedLanguage]}}</th>
                <th class="angular-table-th">{{confChange.documentaionLabel[selectedLanguage]}}</th>
                <th class="angular-table-th">Actions</th>
              </ng-container>

            </tr>
            </thead>
            <ng-container *ngFor="let row of table.rows; let rowIndex = index;">
              <tr *ngIf="!row.isTotal" [hidden]="table.hidden" class="angular-table-tr">

                <td class="angular-table-td">
                  <mat-icon style="cursor: pointer; margin-right: 5px;" (click)="showTableRow(row, rowIndex)">
                    {{row.isShowTableRow ? 'remove_circle_outline' : 'add_circle_outline'}}
                  </mat-icon>
                </td>

                <td class="angular-table-td wrap-table-text" width="10%">
                  <span>{{row.category.label[selectedLanguage]}}</span>
                  <div
                    [ngClass]="row?.category  && row?.category.error && row?.category.error[selectedLanguage] ? 'hasError' : 'noError'">
                     <span *ngIf="row?.category && row?.category.error && row?.category.error[selectedLanguage]"
                           class="formFieldError">{{row?.category.error[selectedLanguage]}}</span>
                  </div>
                </td>


                <ng-container *ngIf="row.category || table.hasOptionSelected">
                  <td class="angular-table-td wrap-table-text">
                    <span>{{row?.subCategory?.label ? row?.subCategory?.label[selectedLanguage]: ''}}</span>
                    <ng-container *ngIf="isOtherBudgetItemTextAreaEnabled && row?.otherBudgetItem && row?.otherBudgetItem.value">
                      <br>
                      <span *ngIf="row.otherBudgetItem.value.length <= 20">{{truncateText(row.otherBudgetItem.value)}}</span>
                      <span *ngIf="row.otherBudgetItem.value.length > 20" [matTooltip]="row.otherBudgetItem.value">{{truncateText(row.otherBudgetItem.value)}}</span>
                    </ng-container>
                    <div
                      [ngClass]="row?.subCategory && row?.subCategory.error && row?.subCategory.error[selectedLanguage] ? 'hasError' : 'noError'">
                    <span *ngIf="row?.subCategory && row?.subCategory.error && row?.subCategory.error[selectedLanguage]"
                          class="formFieldError">{{row?.subCategory.error[selectedLanguage]}}</span>
                    </div>
                  </td>

                  <td class="angular-table-td wrap-table-text">
                    <div style="overflow: auto; max-height: 120px;">{{row.description.value}}</div>
                    <div
                      [ngClass]="row?.description.error && row?.description.error && row?.description.error[selectedLanguage] ? 'hasError' : 'noError'">
                    <span *ngIf="row?.description.error && row?.description.error && row?.description.error[selectedLanguage]"
                          class="formFieldError">{{row?.description.error[selectedLanguage]}}</span>
                    </div>
                  </td>
                  <!--       new part           -->
                  <td *ngIf="confChange.hasJustificationAndDurationMonths" class="angular-table-td wrap-table-text">
                    <div style="overflow: auto; max-height: 120px;">{{row.justification.value}}</div>
                    <div
                      [ngClass]="row?.justification.error && row?.justification.error && row?.justification.error[selectedLanguage] ? 'hasError' : 'noError'">
                    <span *ngIf="row?.justification.error && row?.justification.error && row?.justification.error[selectedLanguage]"
                          class="formFieldError">{{row?.justification.error[selectedLanguage]}}</span>
                    </div>
                  </td>
                  <!---->
                  <td *ngIf="confChange.hasJustificationAndDurationMonths" class="angular-table-td wrap-table-text">
                    <div style="overflow: auto; max-height: 120px;">{{row.durationMonths.value}}</div>
                    <div
                      [ngClass]="row?.durationMonths.error && row?.durationMonths.error && row?.durationMonths.error[selectedLanguage] ? 'hasError' : 'noError'">
                    <span *ngIf="row?.durationMonths.error && row?.durationMonths.error && row?.durationMonths.error[selectedLanguage]"
                          class="formFieldError">{{row?.durationMonths.error[selectedLanguage]}}</span>
                    </div>
                  </td>
                  <!--       end newpart           -->
                  <td class="angular-table-td">
                    <span>{{convertNumberToCurrencyFormat(row.grandSubTotal.value, confChange.currency)}}</span>
                    <div
                      [ngClass]="row.grandSubTotal.error && row.grandSubTotal.error && row.grandSubTotal.error[selectedLanguage] ? 'hasError' : 'noError'">
                    <span
                      *ngIf="row.grandSubTotal.error && row.grandSubTotal.error && row.grandSubTotal.error[selectedLanguage]"
                      class="formFieldError">
                      {{row.grandSubTotal.error[selectedLanguage]}}
                    </span>
                    </div>
                  </td>
                  <td *ngIf="confChange.fundsSplitInstitution" class="angular-table-td">
                    <span>{{convertNumberToCurrencyFormat(row.grandTotalForInstitutionFunds.value, confChange.currency)}}</span>
                    <div
                      [ngClass]="row.grandTotalForInstitutionFunds.error && row.grandTotalForInstitutionFunds.error && row.grandTotalForInstitutionFunds.error[selectedLanguage] ? 'hasError' : 'noError'">
                    <span
                      *ngIf="row.grandTotalForInstitutionFunds.error && row.grandTotalForInstitutionFunds.error && row.grandTotalForInstitutionFunds.error[selectedLanguage]"
                      class="formFieldError">
                      {{row.grandTotalForInstitutionFunds.error[selectedLanguage]}}
                    </span>
                    </div>
                  </td>
                  <td *ngIf="confChange.fundsSplitInstitution" class="angular-table-td">
                    <span>{{convertNumberToCurrencyFormat(row.grandTotalForOtherFunds.value, confChange.currency)}}</span>
                    <div
                      [ngClass]="row.grandTotalForOtherFunds.error && row.grandTotalForOtherFunds.error && row.grandTotalForOtherFunds.error[selectedLanguage] ? 'hasError' : 'noError'">
                      <span
                        *ngIf="row.grandTotalForOtherFunds.error && row.grandTotalForOtherFunds.error && row.grandTotalForOtherFunds.error[selectedLanguage]"
                        class="formFieldError">
                        {{row.grandTotalForOtherFunds.error[selectedLanguage]}}
                      </span>
                    </div>
                  </td>

                  <ng-container *ngIf="confChange.hasComments">
                    <td class="angular-table-td wrap-table-text">
                      <div style="overflow: auto; max-height: 120px;">{{row.comments.value}}</div>
                      <div
                        [ngClass]="row?.comments.error && row?.comments.error && row?.comments.error[selectedLanguage] ? 'hasError' : 'noError'">
                        <span *ngIf="row?.comments.error && row?.comments.error && row?.comments.error[selectedLanguage]"
                              class="formFieldError">{{row?.comments.error[selectedLanguage]}}</span>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container>
                    <td class="angular-table-td" style="font-weight: 500 !important;">
                  <span *ngIf="row.uploadFile.value?.length > 0" style="font-size: 1.1em">
                    <a *ngFor="let upl of row.uploadFile.value" href="/api/viewAttachedRepositoryDocument/{{upl.id}}" target="_blank">{{upl.fileName}}
                    </a>
                  </span>
                    <div [ngClass]="row?.uploadFile.error && row?.uploadFile.error && row?.uploadFile.error[selectedLanguage] ? 'hasError' : 'noError'">
                        <span *ngIf="row?.uploadFile && row?.uploadFile.error && row?.uploadFile.error[selectedLanguage]"
                              class="formFieldError">{{row?.uploadFile.error[selectedLanguage]}}</span>
                    </div>

                    </td>
                  </ng-container>

                  <td class="angular-table-td">
                    <button mat-icon-button (click)="menuTrigger.openMenu();$event.stopPropagation()"
                            #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" class="mat-icon-button">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="onEditTable(row, rowIndex, table)">
                        {{'edit' | translate}}
                      </button>
                      <button mat-menu-item (click)="onDeleteTable(row, rowIndex, table)" [disabled]="confChange.isReadOnlyField">
                        {{'ecase.admindashboard.study.deleteAction' | translate}}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>
              </tr>
              <ng-container *ngIf="row.isShowTableRow && !row.isTotal">
                <tr [hidden]="table.hidden" class="angular-table-tr" *ngFor="let expandableRows of row.expandRows">

                  <td class="angular-table-td" style="background-color: lightgrey;"></td>
                  <td class="angular-table-td" style="background-color: lightgrey;"></td>
                  <td class="angular-table-td" style="background-color: lightgrey;"></td>
                  <td class="angular-table-td" style="background-color: lightgrey;"><span style="height: 100%;width: 20%;position: absolute;right: 0;top: 0;background:white;"><span style="padding-top: 13px;width: 100%;position: absolute;">{{expandableRows.label[selectedLanguage] + " :"}}</span></span></td>
                  <!--<ng-container>-->
                  <!--<td class="angular-table-td"></td>-->
                  <!--<td class="angular-table-td" style="text-align: left;">-->
                  <!--{{expandableRows.label}}-->
                  <!--</td>-->
                  <!--</ng-container>-->

                  <td class="angular-table-td">
                    <div>
                      <span>{{convertNumberToCurrencyFormat(expandableRows.subTotal.value, confChange.currency)}}</span>
                      <div
                        [ngClass]="expandableRows?.subTotal && expandableRows?.subTotal.error && expandableRows?.subTotal.error[selectedLanguage] ? 'hasError' : 'noError'">
                      <span *ngIf="expandableRows?.subTotal && expandableRows?.subTotal.error[selectedLanguage]"
                            class="formFieldError">{{expandableRows?.subTotal.error[selectedLanguage]}}
                      </span>
                      </div>
                    </div>
                  </td>

                  <td *ngIf="confChange.fundsSplitInstitution" class="angular-table-td">
                    <div>
                      <span>{{convertNumberToCurrencyFormat(expandableRows.fundsFromInstitution.value, confChange.currency)}}</span>
                      <div
                        [ngClass]="expandableRows?.fundsFromInstitution && expandableRows?.fundsFromInstitution.error && expandableRows?.fundsFromInstitution.error[selectedLanguage] ? 'hasError' : 'noError'">
                      <span
                        *ngIf="expandableRows?.fundsFromInstitution && expandableRows?.fundsFromInstitution.error[selectedLanguage]"
                        class="formFieldError">{{expandableRows?.fundsFromInstitution.error[selectedLanguage]}}
                      </span>
                      </div>
                    </div>
                  </td>

                  <td *ngIf="confChange.fundsSplitInstitution" class="angular-table-td">
                    <div>
                      <span>{{convertNumberToCurrencyFormat(expandableRows.fundsFromOtherInstitution.value, confChange.currency)}}</span>
                      <div
                        [ngClass]="expandableRows?.fundsFromOtherInstitution && expandableRows?.fundsFromOtherInstitution.error && expandableRows?.fundsFromOtherInstitution.error[selectedLanguage] ? 'hasError' : 'noError'">
                      <span
                        *ngIf="expandableRows?.fundsFromOtherInstitution && expandableRows?.fundsFromOtherInstitution.error[selectedLanguage]"
                        class="formFieldError">
                        {{expandableRows?.fundsFromOtherInstitution.error[selectedLanguage]}}
                      </span>
                      </div>
                    </div>
                  </td>

                  <td class="angular-table-td" style=" background-color: lightgrey;"></td>
                  <td class="angular-table-td" style=" background-color: lightgrey;"></td>
                  <td class="angular-table-td" style=" background-color: lightgrey;"></td>

                </tr>
              </ng-container>
              <!--*ngIf="row.isTotal"-->
              <tr *ngIf="row.isTotal && row.isCategoryTotal " [hidden]="table.hidden" [style]="!row.isCategoryTotal ? 'background-color: #dadada;' : 'background-color: #cccccc;'">
                <td class="angular-table-td"></td>
                <td class="angular-table-td"></td>
                <td class="angular-table-td"></td>
                <td class="angular-table-td"></td>
                <!--      new part          -->
                <td *ngIf="confChange.hasJustificationAndDurationMonths" class="angular-table-td"></td>
                <td *ngIf="confChange.hasJustificationAndDurationMonths" class="angular-table-td"></td>
                <!--  end new part
                                <td class="angular-table-td">{{row.totalText[selectedLanguage]}}</td>
                                <ng-container>-->
                <!--<td class="angular-table-td"></td>-->
                <!--<td class="angular-table-td" style="text-align: left;">-->
                <!--{{expandableRows.label}}-->
                <!--</td>-->
                <!--</ng-container>-->

                <td class="angular-table-td">
                  <div>
                    <span>{{convertNumberToCurrencyFormat(row.total, confChange.currency)}}</span>
                  </div>
                </td>

                <td *ngIf="confChange.fundsSplitInstitution" class="angular-table-td"></td>
                <td *ngIf="confChange.fundsSplitInstitution" class="angular-table-td"></td>
                <td class="angular-table-td"></td>
                <td class="angular-table-td"></td>
                <td class="angular-table-td"></td>
              </tr>

            </ng-container>
          </ng-container>
        </table>
        <button *ngIf="!table.hidden && table.teamFunded === '1'" class='addBtn' mat-raised-button color="accent" style="margin-top: 2px;margin-bottom: 10px;"
                [disabled]="confChange.isReadOnlyField"
                (click)="onAddTable(table)">
          {{ confChange.addLabel[selectedLanguage]}}
        </button>
      </ng-container>
      </ng-container>
    </ng-container>
    <div *ngIf="confChange.showSummaryTable && !showAddTableForm" class="readOnlyTable" style="margin-top: 15px;">
      <table class="angular-table">
        <tr (click)="toggleTable(table)" class="angular-table-tr header-table">
          <th colspan="8">
            {{getTranslatedLabel('ecase.admindashboard.budget.summarytable') === 'ecase.admindashboard.budget.summarytable' ? summaryTableDefaultLabel[selectedLanguage] : getTranslatedLabel('ecase.admindashboard.budget.summarytable')}}
          </th>
        </tr>
        <tr class="angular-table-tr">
          <th class="angular-table-th">{{confChange.categoryLabel[selectedLanguage]}}</th>
          <th *ngIf="!confChange.hideSubcategoriesInSummaryTable"  class="angular-table-th">{{confChange.subCategoryLabel[selectedLanguage]}}</th>

          <ng-container *ngFor="let row of bphChange.yearRows | keyvalue">
            <th class="angular-table-th">{{confChange.yearLabel[selectedLanguage]}} {{+row.key + 1}}</th>
          </ng-container>

          <th class="angular-table-th">{{confChange.subtotalLabel[selectedLanguage]}}</th>
          <th *ngIf="confChange.fundsSplitInstitution" class="angular-table-th">{{confChange.fundsFromInstitutionLabel[selectedLanguage]}}</th>
          <th *ngIf="confChange.fundsSplitInstitution" class="angular-table-th">{{confChange.fundsFromOtherInstitutionLabel[selectedLanguage]}}</th>
        </tr>
        <tr *ngIf="false" class="angular-table-tr" class="descriptionRow">
          <td class="angular-table-td"></td>
          <td class="angular-table-td"></td>
          <ng-container *ngFor="let row of bphChange.yearRows | keyvalue">
            <td class="angular-table-td"></td>
          </ng-container>
          <td class="angular-table-td"> {{replaceDescriptionLabelWithYears(confChange.subTotalDescription)}}</td>
          <td *ngIf="confChange.fundsSplitInstitution" class="angular-table-td">{{replaceDescriptionLabelWithYears(confChange.fundsFromInstitutionDescription)}}</td>
          <td *ngIf="confChange.fundsSplitInstitution" class="angular-table-td">
            {{replaceDescriptionLabelWithYears(confChange.fundsFromOtherInstitutionDescription)}}
          </td>
        </tr>
        <ng-container *ngFor="let category of sortArray(filterInvalidCategories(bphChange.summaryTable.categories) | keyvalue)">
          <ng-container *ngIf="!confChange.hideSubcategoriesInSummaryTable">
            <ng-container *ngFor="let subCategory of category.value.subCategories | keyvalue">
              <tr class="angular-table-tr" class="regularCellStyle">
                <!--<td class="angular-table-td" style="visibility: visible !important;"><span *ngIf="i === 0">{{confChange?.mainCategoryName[0].label[selectedLanguage]}}</span></td>-->
                <td class="angular-table-td" style="visibility: visible !important;"><span>{{category.value?.label[selectedLanguage]}}</span>
                </td>
                <td class="angular-table-td" style="visibility: visible !important;"><span>{{subCategory.value?.label[selectedLanguage]}}</span>
                </td>

                <ng-container *ngFor="let year of subCategory.value?.yearRows;">
                  <td class="angular-table-td">
                    {{convertNumberToCurrencyFormat(year.value, confChange.currency)}}
                  </td>
                </ng-container>

                <td class="angular-table-td">{{convertNumberToCurrencyFormat(subCategory.value?.grandSubTotal.value, confChange.currency)}}</td>
                <td *ngIf="confChange.fundsSplitInstitution" class="angular-table-td">{{convertNumberToCurrencyFormat(subCategory.value?.grandTotalForInstitutionFunds.value, confChange.currency)}}</td>
                <td *ngIf="confChange.fundsSplitInstitution" class="angular-table-td">{{convertNumberToCurrencyFormat(subCategory.value?.grandTotalForOtherFunds.value, confChange.currency)}}</td>
              </tr>
            </ng-container>
          </ng-container>
          <!--<ng-container *ngFor="let subCategory of category.value.subCategories | keyvalue">-->
          <!--<tr class="angular-table-tr" class="regularCellStyle">-->
          <!--&lt;!&ndash;<td class="angular-table-td" style="visibility: visible !important;"><span *ngIf="i === 0">{{confChange?.mainCategoryName[0].label[selectedLanguage]}}</span></td>&ndash;&gt;-->
          <!--<td class="angular-table-td" style="visibility: visible !important;"><span>{{category.value?.label[selectedLanguage]}}</span>-->
          <!--</td>-->
          <!--<td class="angular-table-td" style="visibility: visible !important;"><span>{{subCategory.value?.label[selectedLanguage]}}</span>-->
          <!--</td>-->

          <!--<ng-container *ngFor="let year of subCategory.value?.yearRows;">-->
          <!--<td class="angular-table-td">-->
          <!--{{convertNumberToCurrencyFormat(year.value, confChange.currency)}}-->
          <!--</td>-->
          <!--</ng-container>-->

          <!--<td class="angular-table-td">{{convertNumberToCurrencyFormat(subCategory.value?.grandSubTotal.value, confChange.currency)}}</td>-->
          <!--<td *ngIf="confChange.fundsSplitInstitution" class="angular-table-td">{{convertNumberToCurrencyFormat(subCategory.value?.grandTotalForInstitutionFunds.value, confChange.currency)}}</td>-->
          <!--<td *ngIf="confChange.fundsSplitInstitution" class="angular-table-td">{{convertNumberToCurrencyFormat(subCategory.value?.grandTotalForOtherFunds.value, confChange.currency)}}</td>-->
          <!--</tr>-->
          <!--</ng-container>-->

          <tr class="angular-table-tr" class="subTotalStyles">
            <td class="angular-table-td">
              <span *ngIf="confChange.subtotalLabel && category.value && category.value.label">{{confChange.subtotalLabel[selectedLanguage] + ' ' + category.value?.label[selectedLanguage]}}</span>
            </td>
            <td *ngIf="!confChange.hideSubcategoriesInSummaryTable" class="angular-table-td" class="lightBackground"></td>
            <ng-container *ngFor="let yearTotal of category.value?.yearRows;">
              <td class="angular-table-td">{{convertNumberToCurrencyFormat(yearTotal.value, confChange.currency)}}</td>
            </ng-container>
            <td class="angular-table-td">{{convertNumberToCurrencyFormat(category.value?.grandSubTotal.value, confChange.currency)}}</td>
            <td *ngIf="confChange.fundsSplitInstitution" class="angular-table-td">
              {{convertNumberToCurrencyFormat(category.value?.grandTotalForInstitutionFunds.value, confChange.currency)}}
            </td>
            <td *ngIf="confChange.fundsSplitInstitution" class="angular-table-td">
              {{convertNumberToCurrencyFormat(category.value?.grandTotalForOtherFunds.value, confChange.currency)}}
            </td>
          </tr>
        </ng-container>

        <tr class="angular-table-tr" class="totalStyles">
          <td class="angular-table-td">{{confChange.grandTotalLabel[selectedLanguage]}}</td>
          <td *ngIf="!confChange.hideSubcategoriesInSummaryTable" class="angular-table-td" class="lightBackground"></td>
          <ng-container *ngFor="let yearTotal of bphChange.yearRows;">
            <td class="angular-table-td">{{convertNumberToCurrencyFormat(yearTotal.value, confChange.currency)}}</td>
          </ng-container>
          <td class="angular-table-td">{{convertNumberToCurrencyFormat(bphChange.grandSubTotal.value, confChange.currency)}}</td>
          <td *ngIf="confChange.fundsSplitInstitution" class="angular-table-td">{{convertNumberToCurrencyFormat(bphChange.grandTotalForInstitutionFunds.value, confChange.currency)}}</td>
          <td *ngIf="confChange.fundsSplitInstitution" class="angular-table-td">{{convertNumberToCurrencyFormat(bphChange.grandTotalForOtherFunds.value, confChange.currency)}}</td>
        </tr>

      </table>
<!--      <span *ngIf="bphChange?.error[selectedLanguage]" style="color: red !important; font-weight: bold !important;">{{bphChange.error[selectedLanguage]}}</span>-->
    </div>
    <br />
    <div *ngIf="confChange.showSummaryTableByTeam && !showAddTableForm" class="readOnlyTable" style="margin-top: 15px;">
      <table class="angular-table">
        <tr class="angular-table-tr header-table">
          <th colspan="8">{{'Résumé budgétaire' | translate}}</th>
        </tr>
        <tr class="angular-table-tr">
          <th class="angular-table-th">{{'Catégorie' | translate}}</th>
          <ng-container *ngFor="let team of getTeamNames(bphChange?.categoryTeamData?.data)">
            <th class="angular-table-th">{{team}}</th>
          </ng-container>
          <th class="angular-table-th">{{'Total' | translate}}</th>
        </tr>

        <ng-container *ngFor="let categoryData of bphChange?.categoryTeamData?.data | keyvalue">
          <tr class="angular-table-tr subTotalStyles">
            <td class="angular-table-td">{{'Total ' + categoryData.key}}</td>
            <ng-container *ngFor="let team of getTeamNames(bphChange?.categoryTeamData?.data)">
              <td class="angular-table-td">{{convertNumberToCurrencyFormat(categoryData.value.teams[team], confChange.currency)}}</td>
            </ng-container>
            <td class="angular-table-td">{{convertNumberToCurrencyFormat(categoryData.value.sum, confChange.currency)}}</td>
          </tr>
        </ng-container>

        <tr class="angular-table-tr totalStyles">
          <td class="angular-table-td">{{confChange.summaryGrandTotalLabel[selectedLanguage]}}</td>

          <!-- Team-wise Totals -->
          <ng-container *ngFor="let team of getTeamNames(bphChange?.categoryTeamData?.data)">
            <td class="angular-table-td">
              {{convertNumberToCurrencyFormat(calculateTeamTotals(bphChange?.categoryTeamData)[team], confChange.currency)}}
            </td>
          </ng-container>

          <!-- Grand Total -->
          <td class="angular-table-td">
            {{convertNumberToCurrencyFormat(bphChange?.categoryTeamData['grand total'], confChange.currency)}}
          </td>
        </tr>
      </table>
    </div>


    <br/>
  </ng-container>
  <div class="field">
    <span class="formFieldError" *ngIf="bphChange?.error[selectedLanguage]">{{bphChange.error[selectedLanguage]}}</span>
  </div>

  <div *ngIf="showAddTableForm">

    <div>
      <span style="color: red">*</span> {{confChange.categoryLabel[selectedLanguage]}}
      <mat-form-field appearance="outline">
        <mat-select (ngModelChange)="changeCat($event,tempRow,selectedTable.categoryList)" [(ngModel)]="tempRow.category.value"
                    [disabled]="confChange.isReadOnlyField">
          <mat-option *ngFor="let category of selectedTable.categoryList" [value]="category.value">
            {{category.label[selectedLanguage]}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div><span style="color: red">*</span> {{confChange.subCategoryLabel[selectedLanguage]}}
      <span *ngIf="confChange.hasDialogForSubCategory">
      <button  mat-icon-button (click)="openDialog(confChange.dialogForSubCategory)"  style="margin-left: -10px;margin-bottom: -10px;">
      <mat-icon
        style="cursor: pointer; font-size: 21px;"
        (click)="openDialog(confChange.dialogForSubCategory)">help_outline
      </mat-icon></button></span>
    </div>

    <mat-form-field appearance="outline">

      <ng-container>
        <mat-select id="subcategory" (ngModelChange)="changeSubCat($event,tempRow,tempRow.category.children)" [(ngModel)]="tempRow.subCategory.value"
                    [disabled]="confChange.isReadOnlyField || !tempRow.category.children">
          <mat-option *ngFor="let subCategory of tempRow.category.children"
                      [value]="subCategory.value">
            {{subCategory.label[selectedLanguage]}}
          </mat-option>
        </mat-select>
      </ng-container>
    </mat-form-field>

    <div *ngIf="isOtherBudgetItemTextAreaEnabled && tempRow.subCategory && tempRow.subCategory.isOtherItem">
      <label><span style="color: red">*</span> {{confChange.otherBudgetItemLabel[selectedLanguage]}}</label>
      <mat-form-field appearance="outline">
        <textarea id = 'otherBudgetItem' matInput [rows]="confChange.numberOfRowsForTextarea"
                  [(ngModel)]="tempRow.otherBudgetItem.value"
                  (keyup)="changeValueEvent(tempRow.description.value, 'otherBudgetItem')"></textarea>
      </mat-form-field>
      <p class="counter-style" *ngIf="confChange.isMaxLengthWords">
        {{getWordCount(tempRow.otherBudgetItem.value)}} / {{confChange.maxlength}} {{getTranslatedLabel('ecase.common.words')}}</p>
      <p class="counter-style" *ngIf="!confChange.isMaxLengthWords">
        {{getTextLength(tempRow.otherBudgetItem.value)}} / {{confChange.maxlength}}
        {{getTranslatedLabel('ecase.common.characters')}}</p>
    </div>

    <div>
      <label><span style="color: red">*</span> {{confChange.descriptionLabel[selectedLanguage]}}</label>
      <mat-form-field appearance="outline">
        <textarea id = 'description' matInput [rows]="confChange.numberOfRowsForTextarea" [disabled]="confChange.isReadOnlyField"
                  [(ngModel)]="tempRow.description.value"
                  (keyup)="changeValueEvent(tempRow.description.value, 'description')"></textarea>
      </mat-form-field>
      <p class="counter-style" *ngIf="confChange.isMaxLengthWords">
        {{getWordCount(tempRow.description.value)}} / {{confChange.maxlength}} {{getTranslatedLabel('ecase.common.words')}}</p>
      <p class="counter-style" *ngIf="!confChange.isMaxLengthWords">
        {{getTextLength(tempRow.description.value)}} / {{confChange.maxlength}}
        {{getTranslatedLabel('ecase.common.characters')}}</p>
    </div>
    <!-----------------newPart-->

    <div *ngIf="confChange.hasJustificationAndDurationMonths">
      <label><span style="color: red">*</span> {{confChange.justificationLabel[selectedLanguage]}} </label>
      <mat-form-field appearance="outline">
        <textarea id = 'justification' matInput [rows]="confChange.numberOfRowsForTextarea" [disabled]="confChange.isReadOnlyField"
                  [(ngModel)]="tempRow.justification.value"
                  (keyup)="changeValueEvent(tempRow.justification.value, 'justification')"></textarea>
      </mat-form-field>
      <p class="counter-style" *ngIf="confChange.isMaxLengthWords">
        {{getWordCount(tempRow.justification.value)}} / {{confChange.maxlength}} {{getTranslatedLabel('ecase.common.words')}}</p>
      <p class="counter-style" *ngIf="!confChange.isMaxLengthWords">
        {{getTextLength(tempRow.justification.value)}} / {{confChange.maxlength}}
        {{getTranslatedLabel('ecase.common.characters')}}</p>
    </div>
    <!--TEXT-->
    <div *ngIf="confChange.hasJustificationAndDurationMonths">
      <label><span *ngIf="confChange.isRequired" style="color: red">*</span> {{confChange.DurationMonthsLabel[selectedLanguage]}}</label>
      <mat-form-field appearance="outline">
        <input  id="durationMonths" matInput type="number" min="0" max="99"
                [(ngModel)]="tempRow.durationMonths.value"
                maxlength="2"
                [disabled]="confChange.isReadOnlyField"
                (input)="onSubTotalInput2($event);"
                (keyup)="changeValueEvent(tempRow.durationMonths.value, 'durationMonths')">
      </mat-form-field>
      <p class="counter-style" >
        {{getTextLength3(tempRow.durationMonths.value)}} / 2
        {{getTranslatedLabel('ecase.common.characters')}}</p>
    </div>
    <!-----------------EndnewPart-->
    <div *ngIf="confChange.hasComments">
      <label>{{confChange.commentsLabel[selectedLanguage]}}</label>
      <mat-form-field appearance="outline">
        <textarea id = 'comments' matInput [rows]="confChange.numberOfRowsForTextarea"
                  (keyup)="changeValueEvent(tempRow.comments.value, 'comments')"
                  [(ngModel)]="tempRow.comments.value"></textarea>
      </mat-form-field>
      <p class="counter-style" *ngIf="confChange.isMaxLengthWords">
        {{getWordCount(tempRow.comments.value)}} / {{confChange.maxlength}} {{getTranslatedLabel('ecase.common.words')}}</p>
      <p class="counter-style" *ngIf="!confChange.isMaxLengthWords">
        {{getTextLength(tempRow.comments.value)}} / {{confChange.maxlength}} {{'ecase.common.characters'
        | translate}}</p>

    </div>

    <table id="budget_t1_table" class="angular-table showSecondTable" style="margin-bottom: 1.34375em;">
      <thead class="angular-table-thead">
      <tr class="angular-table-tr">
        <th class="angular-table-th">{{confChange.yearLabel[selectedLanguage]}}</th>
        <th class="angular-table-th">{{confChange.subtotalDetailLabel ? confChange.subtotalDetailLabel[selectedLanguage] : ''}}
        </th>
        <th *ngIf="confChange.fundsSplitInstitution" class="angular-table-th"><span style="color: red">*</span>
          {{confChange.fundsFromInstitutionLabel[selectedLanguage]}}
        </th>
        <th *ngIf="confChange.fundsSplitInstitution" class="angular-table-th"><span style="color: red">*</span>
          {{confChange.fundsFromOtherInstitutionLabel[selectedLanguage]}}
        </th>
      </tr>
      </thead>


      <ng-container *ngFor="let expandableRows of tempRow.expandRows">
        <tr class="angular-table-tr">
          <td class="angular-table-td">{{expandableRows.label[selectedLanguage]}}</td>
          <td class="angular-table-td" style="padding: 2px 18px;">
            <div class="subtotal-field"
                 [ngClass]="expandableRows?.subTotal.error && expandableRows?.subTotal.error && expandableRows?.subTotal.error[selectedLanguage] ? 'hasError' : 'noError'">
              <mat-form-field appearance="outline"
                              [ngClass]="expandableRows?.subTotal.error && expandableRows?.subTotal.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid' : ''">
                <input  id="subtotal" matInput type="number" min="0" max="999999999"
                        [disabled]="confChange.isReadOnlyField"
                        (input)="expandableRows.subTotal.value < 0 ? expandableRows.subTotal.value = 0 : onSubTotalInput(bphChange.selectedTable, tempRow, expandableRows, $event);"
                        [(ngModel)]="expandableRows.subTotal.value" maxlength="9" >
              </mat-form-field>
              <p class="counter-style" style="margin-top: 0px; text-align: right;">
                {{getTextLength2(expandableRows.subTotal.value)}} / 9</p>
              <div *ngIf="expandableRows?.subTotal.error && expandableRows?.subTotal.error[selectedLanguage]"
                   class="formFieldError">{{expandableRows?.subTotal.error[selectedLanguage]}}
              </div>
            </div>
          </td>

          <td *ngIf="confChange.fundsSplitInstitution" class="angular-table-td" style="padding: 2px 18px;">
            <div
              [ngClass]="expandableRows?.fundsFromInstitution && expandableRows?.fundsFromInstitution.error && expandableRows?.fundsFromInstitution.error[selectedLanguage] ? 'hasError' : 'noError'">
              <mat-form-field appearance="outline"
                              [ngClass]="expandableRows?.fundsFromInstitution && expandableRows?.fundsFromInstitution.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid' : ''">
                <input id="institution_funds" matInput type="number" min="0" max="999999999"
                       [disabled]="confChange.isReadOnlyField"
                       (input)="expandableRows.fundsFromInstitution.value < 0 ? expandableRows.fundsFromInstitution.value = 0 : onInstitutionFundsInput(bphChange.selectedTable, tempRow, expandableRows, $event);"
                       [(ngModel)]="expandableRows.fundsFromInstitution.value" maxlength="9">
              </mat-form-field>
              <p class="counter-style" style="margin-top: 0px; text-align: right;">
                {{getTextLength2(expandableRows.fundsFromInstitution.value)}} / 9 </p>
              <div
                *ngIf="expandableRows?.fundsFromInstitution && expandableRows?.fundsFromInstitution.error[selectedLanguage]"
                class="formFieldError">{{expandableRows?.fundsFromInstitution.error[selectedLanguage]}}
              </div>
            </div>
          </td>

          <td *ngIf="confChange.fundsSplitInstitution" class="angular-table-td" style="padding: 2px 18px;">
            <div style="padding-right: 15px"
                 [ngClass]="expandableRows?.fundsFromOtherInstitution && expandableRows?.fundsFromOtherInstitution.error && expandableRows?.fundsFromOtherInstitution.error[selectedLanguage] ? 'hasError' : 'noError'">
              <mat-form-field appearance="outline"
                              [ngClass]="expandableRows?.fundsFromOtherInstitution && expandableRows?.fundsFromOtherInstitution.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid' : ''">
                <input id="other_funds" matInput type="number" min="0"  max="999999999" [disabled]="true"
                       (input)="expandableRows.fundsFromOtherInstitution.value < 0 ? expandableRows.fundsFromOtherInstitution.value = 0 : onOtherInstitutionFundsInput(bphChange.selectedTable, tempRow)"
                       [(ngModel)]="expandableRows.fundsFromOtherInstitution.value" maxlength="9">
              </mat-form-field>
              <p class="counter-style" style="margin-top: 0px; text-align: right;">
                {{getTextLength2(expandableRows.fundsFromOtherInstitution.value)}} / 9</p>
              <div
                *ngIf="expandableRows?.fundsFromOtherInstitution && expandableRows?.fundsFromOtherInstitution.error[selectedLanguage]"
                class="formFieldError">
                {{expandableRows?.fundsFromOtherInstitution.error[selectedLanguage]}}
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
      <tr class="angular-table-tr">
        <td class="angular-table-td"><span>{{confChange.subtotalLabel[selectedLanguage]}}</span></td>
        <td class="angular-table-td">
          <span>{{convertNumberToCurrencyFormat(tempRow.grandSubTotal.value, confChange.currency)}}</span>
        </td>
        <td *ngIf="confChange.fundsSplitInstitution" class="angular-table-td">
          <span>{{convertNumberToCurrencyFormat(tempRow.grandTotalForInstitutionFunds.value, confChange.currency)}}</span>
        </td>
        <td *ngIf="confChange.fundsSplitInstitution" class="angular-table-td">
          <span>{{convertNumberToCurrencyFormat(tempRow.grandTotalForOtherFunds.value, confChange.currency)}}</span>
        </td>
      </tr>

    </table>

    <fb-upload *ngIf="!confChange.hideUploadFile"
      [bphChange]='tempRow.uploadFile'
      [confChange]='confChange.uploadConf'
      [globalConf]='globalConf'
      [globalPrj]='globalPrj'
      [isValidHtml]='true'
      [offlineModeEnabled]='false'
      [selectedLanguage]='selectedLanguage'
      [blockIndex]='blockIndex'
      [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
    </fb-upload>

    <button id="saveBtn" style="margin-right: 1%;" mat-raised-button class="eCase-dialog-positive-action-button"
            [disabled]="confChange.isReadOnlyField"
            (click)="confirm()">
      {{getTranslatedLabel("save")}}
    </button>


    <button id="cancelBtn" mat-raised-button color="accent" (click)="cancel()">
      {{'ecase.common.cancel' | translate}}
    </button>

  </div>
</div>
